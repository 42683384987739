class Configuration {
    APi_HOST = process.env.APi_HOST
    API_CONTEXT_ROOT = ""
    API_BASE_URL = this.APi_HOST + this.API_CONTEXT_ROOT + process.env.API_URL_SEPARATOR;
    //API_BASE_URL = "http://testitcauto2.aeso.ca:7001/itc/public/api/v1.0/";
    // API_BASE_URL = "http://localhost:9999/";
    IS_DUMMY_DATA = (process.env.IS_DUMMY_DATA === 'true'); 
    FORECAST_REPORT_MAX_MONTHS=13;
    HISTORIC_REPORT_MAX_DAYS=100;
    HISTORIC_REPORT_MIN_DATE=new Date(1999,10,23);
    env=process.env.level; //dev, test, integ
    ITC_BUILD_VERSION=process.env.ITC_BUILD_VERSION;
}
export default new Configuration(); 