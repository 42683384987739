import React from 'react';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForgotApiService from '../../apis/ForgotApiService';
import { handleItcApiError } from '../../utilities/HelperMethods';
import parse from 'html-react-parser';

class ForgotApi extends React.Component {

    constructor(props) {
        super(props);
        this.forgotApiService = new ForgotApiService();
        this.state = {
            errorMesage: "",
            successMesage: "",
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        const form = event.target;
        // const data = new FormData(form);
        this.setState({
            errorMesage: "",
            successMesage: "",
        });
        var payload = {
            "email": form.email.value
        }
        this.forgotApiService.submitForm(payload)
        .then(items => {
            if (items.data.return.user != null && items.data.return.user != undefined && items.data.return.user.email != undefined && items.data.return.user.email != null)
                this.setState({ successMesage: "Key is sent on: " + items.data.return.user.email });
            form.reset();
        }).catch(e => {
                this.setState({ errorMesage: handleItcApiError(e) });
                console.log(this.state.errorMesage);
        });
    }

    render() {
        return (
            <div className="col-md-12 ml-0 mr-0 pl-0 pr-0">
                <div className="row justify-content-center align-items-center">
                    <div className="container ml-0 mr-0 pl-0 pr-0" >
                        <div className="row ">
                            <article class="card-body mx-auto signup" >
                                <h4 class="mt-3 mb-3 pb-2 text-center">Forgot API Key</h4>
                                <form onSubmit={this.handleSubmit}>

                                    {this.state.errorMesage ? (
                                        <div className="aeso-err-msg alert alert-warning">{parse(this.state.errorMesage)}</div>
                                    ) : (this.state.successMesage ? <div className="aeso-err-msg alert alert-success">{parse(this.state.successMesage)}</div> : "")}
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <FontAwesomeIcon icon={faEnvelope} /> </span>
                                        </div>
                                        <input name="email" class="form-control" placeholder="Email address *" type="email" required />
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary btn-block"> Get API Key </button>
                                    </div>
                                    <p class="text-center">If you haven't registered already please <a href="/web/api/register">Register</a> to get access to the API </p>
                                </form>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ForgotApi;