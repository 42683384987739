export const Import_Data = "import"
export const Export_Data = "export"
export const Comments = "reason"
export const GrossOffer = "grossOffer"
export const TTC = "ttc"
export const TRM_System = "trmSystem"
export const TRM_Allocation = "trmAllocation"
export const TRM_Total = "trm"
export const ATC = "atc"
export const BcIntertie="BcIntertie"
export const MatlIntertie="MatlIntertie"
export const SkIntertie="SkIntertie"
export const BcMatlFlowgate="BcMatlFlowgate"
export const SystemlFlowgate="SystemlFlowgate"
export const Allocations="Allocations"
export const NoDataMsg="No data to display"

// for csv export
export const bcIntertie="bcIntertie"
export const matlIntertie="matlInertie"
export const skIntertie="skInertie"
export const bcmatlFlowgate="bcmatlFlowgate"
export const systemFlowgate="systemFlowgate"

export const HistoricalReportFileName="HistoricalIntertiePosting.csv";
export const RealtimeReportFileName="RealTimeIntertiePosting.csv";
export const ForecastReportFileName="ForwardLookingIntertiePosting.csv";

export const menuPages = {
    "menu1":"itc",
    "menu2":"ets",
    "menu3":"register",
    "menu4":"forgot-api",
    "fallback":"fallback",
}

export const en_messages={
    "generic":{
        "itcTitle":"AESO APIs (Application Programming Interface)",
        "apiTitle":"AESO API Documentation",
        "notificationBar":{
            "msg1":"We welcome your feedback! Please fill out our ",
            "msg2":"survey",
            "msg3":" or send us an email at ",
            "msg4":"info@aeso.ca",
            "msg5":"."
        },
        "tabs":{
            "title1":"REAL TIME",
            "title2":"HISTORIC REPORT",
            "title3":"FORECAST REPORT",
            "title4":"BROKEN",
        },
        "footer":{
            "msg1":"The data reported here are the Available Transfer Capability (ATC) limits for the AB-MT, AB-BC and AB-SK intertie lines, and reflect conditions on the Alberta electric grid, as well as conditions in which Alberta's Transmission Reliability Margin (TRM) is increased on British Columbia or Saskatchewan or Montana intertie lines. Note the date and time mentioned in the reports are as per local Mountain time.",
            "msg2":"Interested parties should check outage links:  ",
            "msg3":"Long Range Significant Transmission Outages",
            "msg4":" and ",
            "msg5":"Approved Outages",
            "msg13":".",
            "msg6":"Not adapted to the new look yet? Go ",
            "msg7":"here",
            "msg8":" for the old page format.",
            "msg9":"By signing up or otherwise using this site, you agree to the ",
            "msg10":"Please send questions or feedback about this website to ",
            "msg11":"info@aeso.ca",
            "msg12":"www.aeso.ca"
        },
        "realtime":{
            "view1":"GRAPHICAL VIEW",
            "view2":"GRID VIEW",
            "view2msg1":"The results can be viewed in graphical format.",
            "view1msg1":"The results displayed on-screen can be viewed in grid view and downloaded to a .csv file.",
            "msg1":"Transfer limit not expected to be binding based on offers received for all Intertie ATC values.",
            "msg2":"System TRM values : As described in section 5.2 of ID 2011-001R.",
            "btnMsg":"Last Refreshed ",
            "msg3":"Real Time grid shows results for next 48 hours only.",
            "section1":{
                "title":"Real Time ATC"
            },
            "section3":{
                "title":"Grid View: 48 hours",
            },
            "section2":{
                "title":"Intertie Capability: 48 hours Trend",
                "dtlView":"Detail View",
                "y_value":"Megawatts (MW)",
                "x_value":"Hour Endings (HE)"
            },
            "section4":{
                "title":"Map View : Interconnection Outages",
                "noData":"No outages to display.",
                "tableColName1":"From",
                "tableColName2":"To",
                "tableColName3":"Element Name",
                "tableColName4":"Affected Intertie/Flowgate"
            }
        },
        "historic":{
            "view1":"Alberta hourly ATC data can be obtained for historical dates starting Nov 23, 1999, till the previous hour, with maximum 100 days of data at a time.",
        },
        "forecast":{
            "msg1":"Alberta hourly ATC data can be obtained for forecasted dates up to thirteen months into the future.",
        },
        "common":{
            "date":"Date",
            "hourEnding":"HE",
            "bcAbbreviation":"BC",
            "matlAbbreviation":"MATL",
            "skAbbreviation":"SK",
            "bcMatlAbbreviation":"BC/MATL",
            "systemAbbreviation":"SYSTEM",
            "import":"Import",
            "export":"Export",
            "grossOffers":"Gross Offers",
            "atc":"ATC",
            "ttc":"TTC",
            "trm":"TRM",
            "trmSystem":"System",
            "trmAlloc":"Allocation",
            "trmTotal":"Total",
            "hourEndingGrid":"Hour Ending",
            "interties":"Interties",
            "flowGates":"Flow Gates",
            "comments":"Comments",
            "input":{
                "from":"From",
                "to":"To",
                "requiredChar":"*",
                "msg1":"Select Date Range",
                "msg2":"Select Hour Range",
                "msg3":"Type range (HE-HE)",
                "mapMsg1":"Select Date",
                "mapMsg2":"Select Hour",
            },
            "btn":{
                "msg1":"Apply",
                "msg2":"DOWNLOAD",
                "msg3":"COLLAPSE ALL",
                "msg4":"EXPAND ALL",
            }
        }
    },
    "err":{
        "fromDateRequired" : "From date is required",
        "fromDateBeforeToDate" : "From date must be before the To date",
        "fromDateTodayOrBeforeDate" : "From date must be today or before today",
        "toDateRequired" : "To date is required",
        "toDateTodayOrBeforeDate": "To date must be today or before today",
        "fromDateTodayOrAfterDate" : "From date must be today or after today",
        "toDateTodayOrAfterDate" : "To date must be today or after today",
        "dateRangeTooLarge":"Date range ({0}) is too large for report, maximum range allowed for HTML is {1} days. Please break up your request into smaller ranges",
        "forecastDateRangeTooLarge":"Only {0} months of data can be retrieved at a time, please enter a smaller date range",
        "forecastMaxDate":"There is no data after {0}",
        "hourEndingRangeMsg":"Enter valid HE-HE range between 01-24",
        "api":{
            "msg1":"Oops, Something unexpected happened, Please try again later!!",
            "msg2":"Service is not available.",
            "msg3":"Service is not available.",
            "msg4":"Oops, Server encountered a temporary error, Please try again later!!",
            "title":"Error in HTTP request Call"//not visible to anyone
        },
        "broken":{
            "msg0":"Sorry!!",
            "msg1":"You've gone too far off the grid.",
            "msg2":"We can't find the page you're looking for.",
            "msg3":"Get back on track by ",
            "msg4":"returning home ",
            "msg5":"or find exactly what you're looking for using the search below. And if that doesn't help, you can always ",
            "msg6":"contact us",
            "msg7":" directly."
        }
    },
    "success":{
        "itcTitle":"ATC and Intertie Capability Public Reports",
    },
}
