import React from 'react';
import '../../assets/scss/components/Footer.scss';
import '../../assets/scss/components/Header.scss';
import {en_messages} from '../../constants/Constants';

class Footer extends React.Component {

    render() {
        return (
            <div className="col-md-12  ml-0 mr-0 pl-0 pr-0">
                <div className="row atc-public-reports-logo">
                    <div className="container" >
                        <div className="row atc-public-footer-2">
                            <div className="col-md-12 mt-5 mb-3">
                                <span>
                                    <p>{en_messages.generic.footer.msg9} <a href="https://www.aeso.ca/legal/"> Terms of Use.</a></p>
                                    <p>{en_messages.generic.footer.msg10}<a className ="header-link" href="mailto:info@aeso.ca">{en_messages.generic.footer.msg11}</a></p>
                                    <p className="header-link"><a target="_blank" rel="noopener noreferrer" href="https://www.aeso.ca">{en_messages.generic.footer.msg12}</a></p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

Footer.defaultProps = {
    label: "" // if we do not pass any label from parent, then it will display default value
}

export default Footer;