import React from 'react';
import { faUser, faEnvelope, faPhone, faBuilding, faIdBadge, faEye, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SignupService from '../../apis/SignupService';
import { handleItcApiError } from '../../utilities/HelperMethods';
import parse from 'html-react-parser';
import RCG from 'react-captcha-generator';

class Signup extends React.Component {

    constructor(props) {
        super(props);
        this.signupService = new SignupService();
        this.state = {
            errorMesage: "",
            successMesage: "",
            captcha: ''
        }
        this.check = this.check.bind(this)
        this.result = this.result.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault();
        this.check()
      }
    refresh(e){
        console.log("refresh")
        window.location.reload(false);
    }

      result(text) {
        this.setState({
            captcha: text
          })
      }

      check() {
        console.log(this.state.captcha, this.captchaEnter.value, this.state.captcha === this.captchaEnter.value)
        if(this.state.captcha === this.captchaEnter.value){
            return true;
        }else{
           return false;
        }
    }

   

    handleSubmit = event => {
        event.preventDefault();
        if(this.check()){
            
        const form = event.target;
        this.setState({
            errorMesage: "",
            successMesage: "",
        });
        var payload = {
            "firstName": form.firstName.value,
            "lastName": form.lastName.value,
            "email": form.email.value,
            //"phoneNumber": form.phoneNumber.value,
            //"countryCode": form.countryCode.value,
            //"jobType": form.jobType.value,
            //"organization": form.organization.value
        }
        this.signupService.submitForm(payload).then(items => {
            if (items.data.return.user != null && items.data.return.user != undefined && items.data.return.user.email != undefined && items.data.return.user.email != null)
                this.setState({ successMesage: "Key is sent on: " + items.data.return.user.email });
            form.reset();
        })
            .catch(e => {
                this.setState({ errorMesage: handleItcApiError(e) });
                console.log(this.state.errorMesage);
            });
        }else{
                this.setState({ errorMesage: "<li> The entered text didn't matched. Please try again. </li>"});
                
            }
    }

    render() {
        return (
            <div className="col-md-12 ml-0 mr-0 pl-0 pr-0">
			
            <div className="row justify-content-center align-items-center">
	              <div className="container ml-0 mr-0 pl-0 pr-0" >
                    <div className="row ">
                    <article class="card-body mx-auto signup" >
                        <h4 class="mt-3 mb-3 pb-2 text-center">Get a new API Key</h4>
                        <form onSubmit={this.handleSubmit}>

                            {this.state.errorMesage ? (
                                <div className="aeso-err-msg alert alert-warning">{parse(this.state.errorMesage)}</div>
                            ) : (this.state.successMesage ? <div className="aeso-err-msg alert alert-success">{parse(this.state.successMesage)}</div> : "")}



                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <FontAwesomeIcon icon={faUser} /></span>
                                </div>
                                <input name="firstName" class="form-control signup-name" placeholder="First Name *" type="text" required />
                                <input name="lastName" class="form-control signup-name" placeholder="Last name *" type="text" required />
                            </div>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <FontAwesomeIcon icon={faEnvelope} /> </span>
                                </div>
                                <input name="email" class="form-control" placeholder="Email address *" type="email" required />
                            </div>
                            {/* <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <FontAwesomeIcon icon={faPhone} /> </span>
                                </div>
                                <select name="countryCode" class="custom-select signup">
                                    <option selected value="1">+1</option>
                                </select>
                                <input name="phoneNumber" class="form-control" placeholder="Phone number" type="text" />
                            </div>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <FontAwesomeIcon icon={faBuilding} />  </span>
                                </div>
                                <input name="organization" class="form-control" placeholder="Organization" type="text" />
                            </div>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <FontAwesomeIcon icon={faIdBadge} />  </span>
                                </div>
                                <input name="jobType" class="form-control" placeholder="Job" type="text" />
                            </div> */}
                           
                            <div class="form-group input-group">
                               
                            <div class="input-group-prepend">
                                    <span class="input-group-text"> <FontAwesomeIcon icon={faEye} />  </span>
                                </div>
                                
                            <input type='text' class="form-control"  ref={ref => this.captchaEnter = ref} name="captchaVal"  placeholder="Please enter the text *" type="text" required/>
                            <RCG result={this.result} />
                            <div class="input-group-prepend">
                              <  FontAwesomeIcon icon={faSyncAlt} size={"2x"}  onClick={this.refresh}/> 
							  
                              </div>
                            </div>
                            
                            
                            
                            
                              
                           
                       
                        
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary btn-block"> Get API Key </button>
                            </div>
                            <p class="text-center">Have an account? <a href="/web/api/forgot-api">Forgot API Key</a> </p>
                        </form>
                    </article>
                    </div>
                </div>
				<div style={{fontSize: '14px'}}>
				   <p class ='text-center'><strong> Collection Notice </strong> </p> 
				   <p class ='text-center'>This personal information is being collected for the purpose of providing and administering access to AESO data, and in accordance with Section 33(c) of the <em>Freedom of Information and Protection of Privacy Act.</em> If you have any questions about the collection or use of this information, please contact the Manager, FOIP and Records Management, 2500, 330 - 5th Ave SW, Calgary, Alberta, T2P 0L4 or by telephone at 403-539-2890.</p>
		        </div>
               
            </div>
        
        </div>
        


        );
    }
}

export default Signup;