import axios from "axios";
import Configuration from '../configuration';
import {en_messages} from '../constants/Constants';


export default axios.create({
  baseURL: Configuration.API_BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

const authInstance = axios.create({
  baseURL: Configuration.API_BASE_URL
});

export const get = async (path) => {
  
    return await authInstance.get(path)
    .then(response => {
      if(response.data.responseCode !== undefined && response.data.responseCode !== null && response.data.responseCode == 200){
        return response;  
      } else if(response.data.responseCode !== undefined && response.data.responseCode !== null && response.data.responseCode != 200){
        var errorMsg;
        if (response.data.errorObject) {
          errorMsg=response.data.errorObject;
        } else if (response.data.message) {
          errorMsg=response.data.message;
        } else {
          errorMsg=en_messages.err.api.msg1;
        }  
        throw new ItcException(en_messages.err.api.title,errorMsg);
      } else {
        errorMsg = en_messages.err.api.msg1;
        throw new ItcException(en_messages.err.api.title,errorMsg);
      }
    })
    .catch(error => {
      if(error.metadata){
        throw error;
      }
      
      if(error.message == 'Network Error'){
        throw new ItcException(en_messages.err.api.title, en_messages.err.api.msg2);
      } 
      var errorMsg;
      var response = error.response;
      if (response === undefined || response ===null) {
        errorMsg = en_messages.err.api.msg1;
      } else if (response.status >= 500) {
        errorMsg = en_messages.err.api.msg4;
      } else if (response.data.errorObject) {
        errorMsg=response.data.errorObject;
      } else if (response.data.message) {
        errorMsg=response.data.message;
      } else {
        errorMsg = en_messages.err.api.msg1;
      }
      throw new ItcException(en_messages.err.api.title,errorMsg);
    });
}

export const post = async (path, payload) => {
  
  return await authInstance.post(path, payload)
  .then(response => {
    if(response.data.responseCode !== undefined && response.data.responseCode !== null && response.data.responseCode == 200){
      return response;  
    } else if(response.data.responseCode !== undefined && response.data.responseCode !== null && response.data.responseCode != 200){
      var errorMsg;
      if (response.data.errorObject) {
        errorMsg=response.data.errorObject;
      } else if (response.data.message) {
        errorMsg=response.data.message;
      } else {
        errorMsg=en_messages.err.api.msg1;
      }  
      throw new ItcException(en_messages.err.api.title,errorMsg);
    } else {
      errorMsg = en_messages.err.api.msg1;
      throw new ItcException(en_messages.err.api.title,errorMsg);
    }
  })
  .catch(error => {
    if(error.metadata){
      throw error;
    }
    
    if(error.message == 'Network Error'){
      throw new ItcException(en_messages.err.api.title, en_messages.err.api.msg2);
    } 
    var errorMsg;
    var response = error.response;
    if (response === undefined || response ===null) {
      errorMsg = en_messages.err.api.msg1;
    } else if (response.status >= 500) {
      errorMsg = en_messages.err.api.msg4;
    } else if (response.data.errorObject) {
      errorMsg=response.data.errorObject;
    } else if (response.data.message) {
      errorMsg=response.data.message;
    }  else {
      errorMsg = en_messages.err.api.msg1;
    }
    throw new ItcException(en_messages.err.api.title,errorMsg);
  });
}

function ItcException(message, metadata) {
  const error = new Error(message);
  error.metadata = metadata;
  return error;
}