import React from 'react';
import '../../assets/scss/view/MainLayout.scss';
import * as constants from '../../constants/Constants';
import configuration from '../../configuration';

class BrokenPage extends React.Component {

    
    constructor(props) {
        super(props);
        this.state = {
            source: {},
            selectedTab: this.props.selectedTab
        }
    }

    componentDidMount() {
        // AESOLoader.singletonRef.hide();
    }

    render() {
        return (
            <div className="container aeso-body ml-0 mr-0 pl-0 pr-0">
                <div className="row ml-0 mr-0 pl-0 pr-0">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="container pl-0 pr-0 ">
                                <div className="row broken-page d-flex justify-content-center">
                                    <div className="col-md-8">
                                        <div className="align-middle">
                                        <h1>{constants.en_messages.err.broken.msg0}</h1>
                                        <h1>{constants.en_messages.err.broken.msg1}</h1>
                                        <p>{constants.en_messages.err.broken.msg2}</p>
                                        <p>{constants.en_messages.err.broken.msg3}<a href={configuration.API_CONTEXT_ROOT +"/web/api"}>{constants.en_messages.err.broken.msg4}</a>{constants.en_messages.err.broken.msg5}<a href="mailto:info@aeso.ca">{constants.en_messages.err.broken.msg6}</a>{constants.en_messages.err.broken.msg7}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BrokenPage;