import React from 'react';
import '../../assets/scss/components/Header.scss';

class Header extends React.Component {
	render() {
		return (
			<div className="container ml-0 mr-0 pl-0 pr-0" >
				<div className="row atc-public-reports-logo">
					<div className="ml-0 mr-0 col-md-12 pl-0 pr-0 mt-3 mb-3">
						<div className="row">
							<div className="col-md-2 pl-4 pr-2 ml-0 mr-0">
								<img alt="..." src={require("../../assets/img/aeso_itc_logo.png")} />
							</div>
							<div className="col-md-10  ml-0 mr-0 pl-0 pr-2" style={{ minWidth: '250px' }}>
								<div className="atc-public-reports-title-title">{this.props.label}</div>
								<div className="atc-public-reports-title-description mt-2 mb-2">This page contains the documentation for AESO APIs. API consumers can find AESO APIs, learn how to use them, request access, and try them out from here. The AESO APIs will be made available in JSON format. To use the APIs, consumers need to register first. If you need help, please let us know at <a className="header-link" href="mailto:info@aeso.ca" rel="noopener noreferrer">info@aeso.ca</a>. Access more APIs through new API Gateway - <a className="header-link" href="https://developer-apim.aeso.ca" rel="noopener noreferrer">https://developer-apim.aeso.ca/</a></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Header.defaultProps = {
	label: "" // if we do not pass any label from parent, then it will display default value
}

export default Header;