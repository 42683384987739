import React from 'react';
import MainLayout from '../main/MainLayout';
import { menuPages } from '../../constants/Constants';
import '../../assets/scss/app.scss';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import itcApiSource from '../../constants/itc-api.json';
import etsApiSource from '../../constants/ets-api.json';

export const history = createBrowserHistory();

class App extends React.Component {
 
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path={"/web/api/"+menuPages.menu3} render={props => <MainLayout {...props} currentVisiblePage={menuPages.menu3} />} exact />
                    <Route path={"/web/api/"+menuPages.menu4} render={props => <MainLayout {...props} currentVisiblePage={menuPages.menu4}/>} exact />
                    <Route path={"/web/api/"+menuPages.menu1} render={props => <MainLayout {...props} currentVisiblePage={menuPages.menu1} source={itcApiSource} />} exact />
                    <Route path={"/web/api/"+menuPages.menu2} render={props => <MainLayout {...props} currentVisiblePage={menuPages.menu2} source={etsApiSource} />} exact />
                    <Route path={"/web/api"} render={props => <MainLayout {...props}/>}  exact />
                    <Route render={props => <MainLayout {...props} currentVisiblePage={menuPages.fallback}/>} exact/>
                </Switch>
            </Router>
        );
    }
}

export default App;