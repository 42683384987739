import React from 'react';

const SideBarLink = props => {
    let name = props.apiLinkData.leftMenu
    let apiLink = props.apiLinkData["x-tagService"];//props.apiLinkData.properties[0].url

    function handleClick() {
      props.updateDefinitionLink(apiLink)
    }

  return (  
    <div className="api-link" onClick={() => handleClick()}>
      {name}
    </div>
  )
}

export default SideBarLink;