import { post } from "./http-client";

class ForgotApiService {

  dummyData =
    {
    }


  submitForm(data) {
    var payload = data;
    return post("api/forgot-api", payload);
  }
}
export default ForgotApiService;