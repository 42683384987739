import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Signup from '../../components/common/Signup';
import BrokenPage from '../fallback/BrokenPage';
import ForgotApi from '../../components/common/ForgotApi';
import SwaggerUI from 'swagger-ui';
import '../../assets/scss/view/Swagger.scss'
import Config from '../../constants/organization_config.json';
import Sidebar from '../../components/common/Sidebar';
import itcApiSource from '../../constants/itc-api.json';
import etsApiSource from '../../constants/ets-api.json';
import { en_messages,menuPages } from '../../constants/Constants';
import PropTypes from 'prop-types';
import configuration from '../../configuration';
import '../../../node_modules/swagger-ui/dist/swagger-ui.css'

class MainLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationConfig: null,
            definitionList: null,
            source: this.props.source,
            currentVisiblePage: this.props.currentVisiblePage
        }
        this.getOrganizationData = this.getOrganizationData.bind(this)
        this.updateDefinitionLink = this.updateDefinitionLink.bind(this)
    }

    componentWillMount() {
        this.setState({
            organizationConfig: Config.orgData,
            isApi: Config.orgData,
        })
    }

    getCurrentComponent = () => {
        switch (this.state.currentVisiblePage) {
            case menuPages.menu1:
            case menuPages.menu2:
                return <div id="api-data"></div>;
            case menuPages.menu3:
                return <Signup />;
            case menuPages.menu4:
                return <ForgotApi />;
            case menuPages.fallback:
                return <BrokenPage />;
            default:
                break;
        }
    }

    componentDidUpdate() {
        switch (this.state.currentVisiblePage) {
            case menuPages.menu1:
            case menuPages.menu2:
                SwaggerUI({
                    domNode: document.getElementById("api-data"),
                    //url: this.state.definitionLink,
                    spec: this.state.source,
					defaultModelsExpandDepth: -1,
					defaultModelExpandDepth: -1
                    
                });
                break;
            case menuPages.menu3:
            case menuPages.menu4:
            case menuPages.fallback:
            default:
                break;
        }
    }

    getOrganizationData(organization) {
        this.setState({
            definitionList: itcApiSource.tags
        });

         this.setState({
             definitionList: itcApiSource.tags.concat(etsApiSource.tags)
         });
    }

    updateDefinitionLink(currentVisiblePage) {
        switch (currentVisiblePage) {
            case menuPages.menu1:
                this.setState({ source: itcApiSource, currentVisiblePage: currentVisiblePage });
                this.props.history.push(configuration.API_CONTEXT_ROOT +"/web/api/"+menuPages.menu1);
                break;
             case menuPages.menu2:
                this.setState({ source: etsApiSource, currentVisiblePage: currentVisiblePage });
                this.props.history.push(configuration.API_CONTEXT_ROOT +"/web/api/"+menuPages.menu2);
                break;
            case menuPages.menu3:
                this.setState({ source: itcApiSource, currentVisiblePage: currentVisiblePage });
                this.props.history.push(configuration.API_CONTEXT_ROOT +"/web/api/"+menuPages.menu3);
                break;
            case menuPages.menu4:
                this.setState({ source: itcApiSource, currentVisiblePage: currentVisiblePage });
                this.props.history.push(configuration.API_CONTEXT_ROOT +"/web/api/"+menuPages.menu4);
                break;
            default:
                this.setState({ source: itcApiSource, currentVisiblePage: currentVisiblePage });
                break;
        }
    }

    render() {
        return (
            <div className="App">
                <div className="container aeso-body ml-0 mr-0 pl-0 pr-0">
                    <div className="row ml-0 mr-0 pl-0 pr-0">
                        <div className="col-md-12 atc-public-reports-logo ml-0 mr-0 pl-0 pr-0">
                            <div className="row justify-content-center align-items-center">
                                <Header label={en_messages.generic.itcTitle} />
                            </div>
                        </div>
                        <div className="col-md-12 ml-0 mr-0 pl-0 pr-0">
                            <div className="container ml-0 mr-0 pl-0 pr-0" >
                                <div className="row ">
                                    <div className="col-md-2 ml-0 mr-0 pl-0 pr-0">
                                        <Sidebar
                                            organizationConfig={this.state.organizationConfig}
                                            definitionList={this.state.definitionList}
                                            updateDefinitionLink={this.updateDefinitionLink}
                                            getOrganizationData={this.getOrganizationData}
                                        />
                                    </div>
                                    <div className="col-md-10 ml-0 mr-0 pl-0 pr-0">
                                        {this.state.commonComponent}
                                        <div className="App">
                                            {this.getCurrentComponent()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12  ml-0 mr-0 pl-0 pr-0">
                        <div className="row justify-content-center align-items-center">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

MainLayout.propTypes = {
    currentVisiblePage: PropTypes.string,
    source: PropTypes.object
};

MainLayout.defaultProps = {
    currentVisiblePage: menuPages.menu1,
    source: itcApiSource
};

export default MainLayout;